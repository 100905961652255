import { createSelector } from '@ngrx/store';

import { INotificationsState } from '../models/notifications-future.model';
import {
  notificationsEntityAdapter,
  notificationsHistoryFeature,
  notificationsHistoryFeatureKey,
  unseenNotificationsEntityAdapter
} from '../notifications-history.feature';

export const { selectAll } = notificationsEntityAdapter.getSelectors();
export const selectAllUnseen = unseenNotificationsEntityAdapter.getSelectors();

const selectNotificationsHistoryFeatureState = (state: INotificationsState) =>
  state[notificationsHistoryFeatureKey];

export const selectNotificationsHistoryPaginatorState = createSelector(
  selectNotificationsHistoryFeatureState,
  (state) => state.paginator
);

export const selectNotificationsHistorySortState = createSelector(
  selectNotificationsHistoryFeatureState,
  (state) => state.sort
);

export const selectNotificationsHistoryFiltersState = createSelector(
  selectNotificationsHistoryFeatureState,
  (state) => state.filters
);

export const selectCallState = createSelector(
  notificationsHistoryFeature.selectCallState,
  (state) => state.callState
);
export const selectUnseenCallState = createSelector(
  notificationsHistoryFeature.selectUnseenCallState,
  (state) => state.unseenCallState
);

export const selectTotalCount = createSelector(
  notificationsHistoryFeature.selectCallState,
  (state) => state.totalCount
);

export const selectUnSeenCount = createSelector(
  notificationsHistoryFeature.selectCallState,
  (state) => state.count
);

export const selectAllEntities = createSelector(
  selectNotificationsHistoryFeatureState,
  selectNotificationsHistoryFiltersState,
  (state) => selectAll(state.entities)
);

export const selectUnseenAllEntities = createSelector(
  selectNotificationsHistoryFeatureState,
  (state) => selectAllUnseen.selectAll(state.unseenEntities)
);
