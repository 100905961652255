import { createFeatureActionType } from '@app/store/utils';
import { createAction, props } from '@ngrx/store';
import {
  AccountNotificationModel,
  AccountNotificationReqPayload
} from '@eros-api/models';
import {
  ISortingDefaultModel,
  IUpdatePaginationDefaultModel
} from '@app-ngrx-utils/base-model';
import { ITemplatesFeatureFiltersState } from '@app/libs/system/features/templates/state';
import { INotificationsHistoryFeatureFiltersState } from '@app/libs/account-notification/features/notifications-history/state/models/notifications-future.model';

const notificationsHistoryPageActionsNamespace = createFeatureActionType(
  'Notifications History Page'
);

export const loadNotificationsHistory = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Notifications History`,
  props<{
    options: Partial<AccountNotificationReqPayload>;
  }>()
);

export const loadNotificationsHistorySuccess = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Notifications History Success`,
  props<{ totalCount: number; entities: AccountNotificationModel[] }>()
);

export const loadNotificationsHistoryFailure = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Notifications History Failure`,
  props<{ error: Error }>()
);

export const loadUnseenNotificationsHistory = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications`,
  props<{
    options: Partial<AccountNotificationReqPayload>;
  }>()
);

export const loadUnseenNotificationsHistorySuccess = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications Success`,
  props<{ totalCount: number; entities: AccountNotificationModel[] }>()
);

export const loadUnseenNotificationsHistoryFailure = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications Failure`,
  props<{ error: Error }>()
);

export const updateNotificationSeenStatus = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notification Seen Status`,
  props<{ notification: AccountNotificationModel }>()
);
export const updateNotificationSeenStatusSuccess = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notification Seen Status Success`,
  props<{ notification: AccountNotificationModel; isSeen: boolean }>()
);

export const updateNotificationSeenStatusFailure = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notification Seen Status Failure`,
  props<{ error: Error }>()
);

export const loadUnseenNotificationsCount = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications Count`
);

export const loadUnseenNotificationsCountSuccess = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications Count Success`,
  props<{ count: number }>()
);

export const loadUnseenNotificationsCountFailure = createAction(
  `${notificationsHistoryPageActionsNamespace} Load Unseen Notifications Count Failure`,
  props<{ count: number }>()
);

export const updatePagination = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notifications Pagination`,
  props<IUpdatePaginationDefaultModel>()
);

export const updateFilter = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notifications Filter`,
  props<{ payload: Partial<INotificationsHistoryFeatureFiltersState> }>()
);

export const updateSorting = createAction(
  `${notificationsHistoryPageActionsNamespace} Update Notifications Sorting`,
  props<{ payload: ISortingDefaultModel }>()
);
