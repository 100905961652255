import {
  selectCallState,
  selectNotificationsHistorySortState,
  selectNotificationsHistoryPaginatorState,
  selectNotificationsHistoryFiltersState,
  selectTotalCount,
  selectUnSeenCount,
  selectUnseenCallState,
  selectAllEntities,
  selectUnseenAllEntities
} from './selectors/notifications-history.selectors';

import { NotificationsHistoryPageEffects } from './effects/notifications-history.effects';

export * as notificationsHistoryPageActions from './actions/notifications-history.actions';

export const notificationsHistoryPageEffects = [
  NotificationsHistoryPageEffects
];

export const notificationsHistorySelectors = {
  selectCallState,
  selectUnseenCallState,
  selectNotificationsHistorySortState,
  selectNotificationsHistoryPaginatorState,
  selectNotificationsHistoryFiltersState,
  selectTotalCount,
  selectUnSeenCount,
  selectUnseenAllEntities,
  selectAllEntities
};

export {
  notificationsHistoryFeatureKey,
  notificationsHistoryFeature
} from './notifications-history.feature';

export { INotificationsHistoryFeatureFiltersState } from './models/notifications-future.model';
