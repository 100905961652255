import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { ICallState, SortDirection } from '@app-ngrx-utils/base-model';
import { CallState, createFeatureFeatureKey } from '@app/store/utils';
import { DEFAULT_INITIAL_PAGINATION_DATA } from '@app-ngrx-utils/constants';

import { AccountNotificationModel } from '@eros-api/models';

import * as notificationsHistoryPageActions from './actions/notifications-history.actions';
import {
  INotificationsEntityState,
  INotificationsHistoryFeatureState
} from './models/notifications-future.model';

export const notificationsHistoryFeatureKey = createFeatureFeatureKey(
  'notifications-history'
);

export interface INotificationHistoryCallState extends ICallState {
  unseenCallState: CallState;
  firstInit: boolean;
  totalCount: number;
  count: number;
}

export const notificationsEntityAdapter: EntityAdapter<AccountNotificationModel> =
  createEntityAdapter<AccountNotificationModel>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

export const unseenNotificationsEntityAdapter: EntityAdapter<AccountNotificationModel> =
  createEntityAdapter<AccountNotificationModel>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

const initialState: INotificationsEntityState =
  notificationsEntityAdapter.getInitialState();

const initialCallState: INotificationHistoryCallState = {
  callState: CallState.Init,
  unseenCallState: CallState.Init,
  error: null,
  firstInit: true,
  totalCount: null,
  count: null
};

export const entitiesFeature = createFeature({
  name: 'NotificationsEntities',
  reducer: createReducer(
    initialState,
    on(
      notificationsHistoryPageActions.loadNotificationsHistorySuccess,
      (state, { entities }) =>
        notificationsEntityAdapter.setAll(entities, {
          ...state
        })
    ),
    on(
      notificationsHistoryPageActions.updateNotificationSeenStatusSuccess,
      (state, { notification }) =>
        notificationsEntityAdapter.updateOne(
          {
            id: notification.id,
            changes: notification
          },
          { ...state }
        )
    )
  )
});

export const unseenEntitiesFeature = createFeature({
  name: 'unseenNotificationsEntities',
  reducer: createReducer(
    initialState,
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsHistorySuccess,
      (state, { entities }) =>
        unseenNotificationsEntityAdapter.setAll(entities, {
          ...state
        })
    ),
    on(
      notificationsHistoryPageActions.updateNotificationSeenStatusSuccess,
      (state, { notification }) =>
        unseenNotificationsEntityAdapter.updateOne(
          {
            id: notification.id,
            changes: notification
          },
          { ...state }
        )
    )
  )
});

export const paginatorFeature = createFeature({
  name: 'notificationsPaginator',
  reducer: createReducer(
    DEFAULT_INITIAL_PAGINATION_DATA,
    on(notificationsHistoryPageActions.updatePagination, (state, payload) => ({
      ...state,
      ...payload
    }))
  )
});

export const sortFeature = createFeature({
  name: 'notificationsSort',
  reducer: createReducer(
    {
      sortDirection: SortDirection.ASC,
      sortBy: 'dateCreated'
    },
    on(notificationsHistoryPageActions.updateSorting, (state, { payload }) => ({
      ...state,
      ...payload
    }))
  )
});

export const filtersFeature = createFeature({
  name: 'notificationsFilter',
  reducer: createReducer(
    {
      accountId: null,
      is_seen: null,
      type: null
    },

    on(notificationsHistoryPageActions.updateFilter, (state, { payload }) => ({
      ...state,
      ...payload
    }))
  )
});

export const callStateFeature = createFeature({
  name: 'callState',
  reducer: createReducer(
    initialCallState,
    on(notificationsHistoryPageActions.loadNotificationsHistory, (state) => {
      return {
        ...state,
        callState: state.firstInit ? CallState.Loading : CallState.Loaded,
        firstInit: false
      };
    }),
    on(
      notificationsHistoryPageActions.loadNotificationsHistorySuccess,
      (state, { totalCount }) => ({
        ...state,
        callState: CallState.Loaded,
        firstInit: false,
        totalCount
      })
    ),
    on(
      notificationsHistoryPageActions.loadNotificationsHistoryFailure,
      (state) => ({
        ...state,
        callState: CallState.Error
      })
    ),
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsHistory,
      (state) => {
        return {
          ...state,
          unseenCallState: CallState.Loading,
          firstInit: false
        };
      }
    ),
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsHistorySuccess,
      (state, { totalCount }) => ({
        ...state,
        unseenCallState: CallState.Loaded,
        firstInit: false,
        totalCount
      })
    ),
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsHistoryFailure,
      (state) => ({
        ...state,
        unseenCallState: CallState.Error
      })
    ),
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsCount,
      (state) => {
        return {
          ...state,
          unseenCallState: CallState.Loading
        };
      }
    ),
    on(
      notificationsHistoryPageActions.loadUnseenNotificationsCountSuccess,
      (state, { count }) => {
        return {
          ...state,
          unseenCallState: CallState.Loaded,
          count
        };
      }
    )
  )
});

export const notificationsHistoryFeature = createFeature({
  name: notificationsHistoryFeatureKey,
  reducer: combineReducers<INotificationsHistoryFeatureState>({
    entities: entitiesFeature.reducer,
    paginator: paginatorFeature.reducer,
    sort: sortFeature.reducer,
    filters: filtersFeature.reducer,
    callState: callStateFeature.reducer,
    unseenCallState: callStateFeature.reducer,
    unseenEntities: unseenEntitiesFeature.reducer
  })
});
